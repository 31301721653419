import React from "react"
import { Container } from "react-bootstrap"
import "./PaymentPlans.scss"
import Slider from "@components/elements/Slider/SSlider"

const PaymentPlanItem = ({ plan }) => {
  if (!plan) return null

  const { title, description } = plan

  return (
    <div className="plan-item">
      <p className="plan-title">{title}</p>
      <p className="plan-description">{description}</p>
    </div>
  )
}

const PaymentPlans = ({ offplan }) => {
  // if (!offplan.payment_plans) return null
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const { payment_plans: plans, add_plan, payment_plan_text_1 } = offplan
  return (
    <div className="payment-plans-wrap section-l-m" id="payment-plans">
      <Container className="payment-plans-container">
        <Slider {...settings}>
          {plans?.length > 0 ? <div className="left-section">
            <h2 className="title">{payment_plan_text_1 ? payment_plan_text_1 : "Payment Plans"}</h2>
            {/* <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit vivamus nec
            felis convallis lorum ipsum.
          </p> */}
            <div className="payment-plans-section">
              {plans.map((plan) => (
                <PaymentPlanItem key={plan.id} plan={plan} />
              ))}
            </div>
          </div> : null}
          {add_plan?.length > 0 ? add_plan?.map((item, i) => {
            return (
              <div className="left-section">
                <h2 className="title">{item?.title}</h2>
                {/* <FinancialInfoForm offplan={offplan} /> */}
                <div className="payment-plans-section">
                  {item?.add_single_plan?.map((plan) => (
                    <PaymentPlanItem key={plan.id} plan={plan} />
                  ))}
                </div>
              </div>
            )
          })
            : null}
        </Slider>
      </Container>
    </div>
  )
}

export default PaymentPlans

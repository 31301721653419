import React from "react"
import "./AmenitiesSlider.scss"
import SliderModule from "@components/SliderModule/SliderModule"
import useDeviceMedia from "@hooks/useDeviceMedia"
import { isEmpty } from "lodash"
import noImg from "@images/noImage.png"
import SliderModuleNew from "@components/SliderModule/SliderModuleNew"

const AmenityCard = ({ amenity }) => {
  if (!amenity) return null
  const { image, text } = amenity

  return (
    <div className="amenity-card">
      <div className="img-section">
        {image ? (
          <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={image.url} alt={text} />
        ) : (
          <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={noImg} alt={text} />
        )}
      </div>
      <p className="name">{text}</p>
    </div>
  )
}

const AmenitiesSlider = ({ amenities }) => {
  const { isTablet } = useDeviceMedia()
  if (isEmpty(amenities)) return null

  const sliderChildren = amenities.map((amenity) => (
    <AmenityCard amenity={amenity} key={amenity.id} />
  ))
  return (
    amenities[0]?.image ?
    <SliderModule
      title="Amenities"
      sliderChildren={sliderChildren}
      wrapperClassname="amenities-slider-wrap"
      size="lg2"
      length={amenities.length}
      showArrows={!isTablet}
      sectionClassname="section-p"
      id="offplan-amenities-slider"
      noSliderArrows
    /> : 
    <SliderModuleNew
      title="Amenities"
      amenities={amenities}
      wrapperClassname="amenities-slider-wrap"
      size="lg2"
      length={amenities.length}
      showArrows={!isTablet}
      sectionClassname="section-p"
      id="offplan-amenities-slider"
      noSliderArrows
    />
    
  )
}

export default AmenitiesSlider

import React from "react"
import "./OffplanBrochure.scss"
import { Container } from "react-bootstrap"
import BrochureModalWithTrigger from "@components/BrochureModal/BrochureModal"
import GGFXImage from "@components/elements/GGFXImage"

const OffplanBrochure = ({ offplan }) => {
  if (!offplan.brochure?.file) return null

  const { image } = offplan.brochure
  const { ggfx_results, strapi_id, title } = offplan

  
  return (
    <div className="offplan-brochure-wrap section-l-m" id="offplan-brochure">
      <Container className="offplan-brochure-container">
        <div className="left-section">
          <h2 className="title">Project Brochure</h2>
          <p className="description">
            All you need to know about {offplan.title}
          </p>
          <BrochureModalWithTrigger offplan={offplan} />
          <p className="text">Get the brochure in less than 10 seconds.</p>
        </div>
        <div className="right-section">
          {/* <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={image.url} alt="brochure" /> */}
          <GGFXImage
            ImageSrc={image}
            altText={title}
            imagetransforms={ggfx_results}
            renderer="srcSet"
            imagename="off-plan.brochure_image.tile_img"
            strapiID={strapi_id}
          />
        </div>
      </Container>
    </div>
  )
}

export default OffplanBrochure
